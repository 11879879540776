body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-levels {
  background-size: cover !important;
  background-color: #0000008a !important;
  background-blend-mode: color !important;
  overflow-x: scroll !important;
  background-image: url(../assets/background.jpeg) !important;
}

@media all and (max-width: 500px) {
  .background-levels {
    padding-left: 120vw !important;
  }
}

.text-center {
  text-align: center;
}

*:not(i) {
  font-family: "Open Sans", sans-serif !important;
}

.right-menu {
  font-size: small !important;
}

@media (max-width: 768px) {
  #navbar {
    overflow-x: auto;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    display: flex;
    flex-direction: column;
  }

  #navbar div {
    margin: 2px;
  }

  .main-logo.middle {
    display: none;
  }
  /* //new mobile adjustments below: */

  #tab-button {
    max-width: 50px !important;
  }

  .mobile-tab-content-container {
    width: 280px !important;
    border: 0px !important;
    text-align: start;
  }

  .mobile-tab-row {
    display: flex !important;
    align-content: flex-start !important;
    margin-left: 0px !important;
  }

  .show-steps {
    display: none !important;
  }
  .registration-title {
    font-weight: 300 !important;
    letter-spacing: 3px !important;
    text-transform: uppercase !important;
    margin-top: 15px !important;
    font-size: x-large !important;
  }
}

@media (min-width: 769px) {
  .tab-content-container {
    border-width: 0 !important;
    text-align: start;
    width: 600px !important;
  }
}

.coupon-code {
  font-family: monospace !important;
}

.subheader {
  color: grey;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  text-align: left;
  align-content: flex-start;
  cursor: auto;
}

.tab-icon {
  display: flex !important;
  justify-content: center !important ;
  align-self: center !important;
  height: 75px !important;
  font-size: x-large !important;
}

#navbar a {
  color: black !important;
}

.welcome-name {
  display: flex;
  font-size: medium;
  letter-spacing: 0.2em;
  font-weight: 300;
  margin-top: 30px;
}

.tab-title {
  align-items: flex-start;
  flex-direction: row;
  font-weight: 300 !important;
  letter-spacing: 4px !important;
  margin-bottom: 15px !important;
}

.tab-menu-container {
  padding-top: 0px !important;
  margin-top: 0em !important;
}

.levels-array-display {
  overflow-x: scroll !important;
  display: flex !important;
  justify-self: stretch !important;
}

.activity-wrapper {
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
}

.dashboard-list {
  margin-left: 0em !important;
  margin-bottom: 50px !important;
  margin-top: 10px !important;
  text-align: start !important;
}

.dashboard-list-item {
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 15px;
}

.item-description {
  display: inline-block;
  margin-top: 5px;
}

.tab-text-description {
  display: flex !important;
  justify-content: space-between !important;
  max-width: 300px;
  margin-top: 2px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.tab-link {
  font-size: small;
  color: gray;
  text-decoration: underline;
}

.chevron-icon {
  color: gray;
  padding-left: 5px;
}

.registration-steps {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 90% !important;
  text-align: center !important;
  flex-direction: row !important;
  align-self: center !important;
}
