.levels-overview .card {
  box-shadow: none;
  max-width: 230px;
}

.levels-overview .card > .content:first-child {
  border-radius: 0px !important;
}

.ui.selection.dropdown {
  width: 100%;
  border-radius: 0px;
}

.ui.search .default.text {
  text-align: center;
  width: 100%;
}

.ui.button.cta:hover {
  background: #191d34;
}

button,
.button {
  border-radius: 0px !important;
}

button.primary {
  background: #1a1a1a !important;
  color: white !important;
}

input {
  border-radius: 0px !important;
}

button.ui.basic.teal.button.active {
  background: azure !important;
}

.ui.vertical.tabular.menu .active.item {
  background-color: #ececec;
  border-color: white;
  border-bottom-color: silver;
}

.ui.grid > .column:not(.row) {
  padding-top: 50px;
  padding-bottom: 50px;
}

.primary {
  text-transform: uppercase !important;
}

.ui.button:not(.mini) {
  min-width: 240px;
  text-transform: uppercase !important;
}

.ui.header {
  text-transform: uppercase;
}

.navbar > div:not(:last-child) {
  margin-right: 10px;
}

.ui.list {
  padding: 0em;
}

@media only screen and (max-width: 767px) {
  .ui.stackable.buttons {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ui.stackable.buttons > .button {
    width: 100%;
  }

  .ui.steps:not(.unstackable) .step {
    width: 40% !important ;
  }
}
