.payment-card .adyen-checkout__input-wrapper {
  width: 100%;
}

.payment-card > .adyen-checkout__card-input {
  margin: 0 auto;
}

.payment-card .adyen-checkout__label__text {
  width: 85%;
}

.payment-applepay > div {
  width: 100%;
}

#payment .adyen-checkout__input,
#payment .adyen-checkout__dropdown__button {
  justify-content: left !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(34, 36, 38, 0.15);
}

#payment .adyen-checkout__input:hover,
#payment .adyen-checkout__dropdown__button:hover {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
}

#payment .adyen-checkout__input--focus,
#payment .adyen-checkout__input:focus,
#payment .adyen-checkout__dropdown__button:focus {
  /* color: rgba(0, 0, 0, 0.87); */
  border-color: #96c8da !important;
  box-shadow: none;
}

#payment .adyen-checkout__input--active,
#payment .adyen-checkout__dropdown__button--active {
  /* color: rgba(0, 0, 0, 0.87); */
  border-color: #96c8da !important;
  box-shadow: none;
}

#payment .adyen-checkout__dropdown__button {
  display: flex;
  justify-content: center;
  border-radius: 0px;
}

#payment .adyen-checkout__label > span > span {
  border-radius: 0px !important;
}
